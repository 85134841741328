export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
    // tag: '2',
    tagVariant: 'light-warning',
  },
  {
    title: 'Mail Box',
    route: 'apps-email',
    icon: 'MailIcon',
    // acl: {
    //   roles: ['parent', 'student', 'staff'],
    // },
  },
]
